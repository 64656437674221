import * as React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCommentDots, faBars } from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"

const NavBarContainer = styled.nav`
    background-color: #05668D;
    color: #E6F5FB;

    .nav-item {
        padding-top: 0.3125rem;
        padding-bottom: 0.125rem;
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .navbar-toggler-icon {
        padding-top: 0.3125rem;
        padding-bottom: 0.125rem;
        color: #E6F5FB;

        &:hover {
            color: #E6F5FB80;
        }
    }
`

const NavBarLink = styled(Link)`
    color: #E6F5FB;
    &:hover {
        color: #E6F5FB;
    }
`

const NavBar = () => {
    return (
        <NavBarContainer className="main-nav px-3 navbar navbar-expand-sm">
            <div className="container-fluid">
                <NavBarLink className="navbar-brand" to="/">
                    <FontAwesomeIcon icon={ faCommentDots } />
                    <span className="px-3">Lemmalytica</span>
                </NavBarLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon">
                    <FontAwesomeIcon icon={ faBars } />
                    </span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav me-auto">
                        <li className="nav-item">
                            <NavBarLink className="navbar-link" to="/app">
                                <span className="px-3">App</span>
                            </NavBarLink>
                        </li>
                        <li className="nav-item">
                            <NavBarLink className="navbar-link" to="/blog">
                                <span className="px-3">Blog</span>
                            </NavBarLink>
                        </li>
                        <li className="nav-item">
                            <NavBarLink className="navbar-link" to="/references">
                                <span className="px-3">References</span>
                            </NavBarLink>
                        </li>
                    </ul>
                </div>
            </div>
        </NavBarContainer>
    )
}

export default NavBar
