import * as React from "react"
import { Link } from "gatsby"
import moment from "moment"
import styled from "styled-components"

const FooterContainer = styled.div`
    position: fixed;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 1.5rem;
    background-color: #203a53;
    color: white;

    nav a {
        color: inherit;
    }
`

const Footer = () => {
    const getCurrentYear = () => moment().format("YYYY");

    return (
        <FooterContainer className="main-footer">
            <div>
                <nav className="nav">
                    <small className="px-2">
                        <Link to="/about">About</Link>
                    </small>
                    <small className="px-2">
                        <Link to="/contact">Contact</Link>
                    </small>
                    <small className="px-2">
                        <Link to="/policies">Policies</Link>
                    </small>
                </nav>
            </div>
            <div>
                <small>
                    &copy; Lemmalytica, { getCurrentYear() }
                </small>
            </div>
        </FooterContainer>
    )
}

export default Footer
